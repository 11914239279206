<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 曹操出行订单
 * @Date: 2021-01-04 14:12:33
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-04 16:50:12
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/Travel.vue
-->
<template>
  <div class="user_content">
    <div class="orderlist">
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <div class="state" style="color:#E33F44;">{{ item.order_status_name }}</div>
          <div class="list_item">
            <div><span>快车</span><span>进行中 <van-icon name="arrow" size="0.2rem" /></span></div>
            <div>
              <van-icon name="underway-o" size="0.2rem" style="margin-right:10px" />
              {{ item.call_time }}
            </div>
            <div><span class="s" style="background: #67BBA4;" /> {{ item.start_name }}</div>
            <div><span class="s" style="background: #EE965F;" /> {{ item.end_name }}</div>
          </div>
          <div class="state">已完成订单</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Icon, Toast } from 'vant'

Vue.use(Empty).use(Toast).use(Icon)
import {
  Travellist
} from '@/services/userApi'

export default {
  data() {
    return {
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: []
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      Travellist().then(res => {
        if (Number(res.code) === 200) {
          this.list = res.list
        }
      })
    },
    tabkey() {
      this.getinfo()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .orderlist {
    & ul li {
      .state {
        color: #999999;
        padding: 13px;
        text-align: left;
        font-size: 13px;
        font-weight: 500;
      }

      .list_item {
        background: #FFFFFF;
        padding: 10px;
        margin: 0 13px;
        border-radius: 4px;

        & > div:nth-child(1) {
          display: flex;
          justify-content: space-between;

          & > span:nth-child(1) {
            font-size: 14px;
            font-weight: 500;
          }

          & > span:nth-child(2) {
            color: #999999;
          }
        }

        & > div {
          text-align: left;
          color: #666666;
          margin-top: 10px;
          display: flex;
          align-items: center;

          .s {
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
